@import '../../../styles/colors';

.userSelectedContainer {
  align-items: center;
  background-color: #f6f6f6 !important;
  border-radius: 5rem !important;
  border: solid #d1d1d1 0.1rem;
  display: flex;
  margin: 0.5rem 0;
  max-height: 55px;
  min-height: 55px;
}

.imageContainer {
  padding-left: 0.5rem;
  padding-right: 0.25rem;
}

.nameContainer {
  color: #009fa0;
  font-weight: bold;
  overflow: hidden;
  padding: 0 0.25rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.clearContainer {
  height: 100%;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  width: 25px;
}

.clearIcon {
  color: #909595 !important;
  cursor: pointer;
}
