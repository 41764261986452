.container {
    padding: 0 2rem;
    max-height: 315px;
    overflow-y: auto;
    border-top: 1px solid #e5e5e5;
    margin: 1rem -1rem -.8rem -1rem;

    .title {
        display: block;
        margin: 1rem 0 .5rem 0;
        cursor: auto;
    }

    .select-all {
        display: flex;
        gap: .7rem;
        align-items: center;
        padding: 10px 0;

        label {
            padding: 0;
            margin: 0;
        }
    }
}
