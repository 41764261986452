@import "../../../styles/colors";

.dropzone {
  margin-top: 1rem;
  opacity: 0.5;
}

.enter {
  opacity: 1;
  transition: opacity 300ms;
}