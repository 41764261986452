@import '../../styles/transitions';
@import '../../styles/font';
@import '../../styles/colors';

.NavbarLink {
  cursor: pointer;
  color: $gray;
  transition: $defaultTransition;

  &:hover {
    color: $white;
  }

  &:active {
    transform: scale(.95);
  }

  svg {
    font-size: $defaultIconFontSize;
  }

  .text {
    margin-left: 5px;
  }
}

.menuContent {
  padding: 10px 0;
}

.popMenu {
  z-index: 9;
}
