@import "../../styles/colors";

.innerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 10px;
  }
}

.centered {
  align-items: center;

  label {
    margin-bottom: 0px !important;
  }
}

.switchContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .status {
    color: #aaa;
    margin-right: 1.5rem;
    font-size: 10.5pt;
    text-transform: capitalize;
  }
}

.configContainer {
  margin: 20px 0px;  
}

.configItem {
  padding: 0.75rem 0rem 0.75rem 0.5rem;

  &:nth-of-type(even) {
    background-color: #f1f5f5;
  }
}