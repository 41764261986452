.render-item {
  border-radius: initial !important;

  &:hover {
    .render-item-sublabel {
      color: #83dfdc !important;
    }
  }

  .render-item-info {
    padding: initial !important;
    width: 95%;
  }

  .render-item-label {
    color: #009fa0;
    display: block;
    font-size: 0.8rem;
    font-weight: 700;
    margin: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: initial;
    text-transform: initial;
    white-space: nowrap;
  }

  .render-item-sublabel {
    color: #7a7a7a !important;
    display: block;
    font-size: 0.8em;
    margin: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
