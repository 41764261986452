.search-filter {
  align-items: center;
  display: flex;
  gap: 10px;
}

.search-select {
  background: transparent;
  height: initial !important;
  width: initial !important;
  min-width: 150px !important;

  & > div {
    align-items: center;
    background: transparent !important;
    border-radius: initial !important;
    border: initial !important;
    display: flex;
    gap: 10px;
    height: initial !important;
    justify-content: start;
    margin: initial !important;
    padding: initial !important;
    width: initial !important;

    .search-option {
      color: #009fa0;
      font-family: Montserrat, sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 11px;
      text-transform: uppercase;
      display: flex;
      gap: 8px;

      & > svg {
        color: #009fa0 !important;
      }
    }

    & > div {
      // display: none;
      border: initial !important;
      width: initial !important;

      div {
        border-color: #009fa0 !important;
        opacity: 1 !important;
      }
    }
  }
}

.search-option {
  align-items: center;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  gap: 10px;
  line-height: 17px;
  margin: initial;
  padding: initial;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}

.search-option:hover {
  color: #009fa0;
}

.search-container {
  margin-bottom: 1rem;
  margin-top: 1rem;

  input {
    background: transparent;
    border: 0px;
    color: #7b7e7e;
    height: 39px;
    margin: 0px;
  }

  ul {
    background: #fff;
    border-radius: 8px;
    z-index: 1 !important;
  }

  ul:has(> *) {
    border: 1px solid #ccc;
    box-shadow: 0 1px 5px rgba(93, 93, 93, 0.18);
    box-sizing: border-box;
    margin-top: 3px;
  }

  & > div {
    border-radius: 8px !important;
    & > div {
      border-radius: 8px !important;

      & > div > div > div {
        border-radius: 8px !important;
      }
    }
  }
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.show-student-list-action {
    color: #019e9b;
    margin-top: .5rem;
    cursor: pointer;
    display: block;

    &:hover {
        text-decoration: underline;
    }
}

.input-wrapper-container > .tools {
  background: white;
  border-top: 1px solid #c3c9c9;
  display: flex;
  justify-content: end;
}
