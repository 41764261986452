@import '../../styles/colors';

.description {
  font-size: 12pt;
  margin-top: 20px;
  color: $darkGray;
}

.separator {
  width: 100%;
  height: 1px;
  border: 0; 
  border-top: 1px solid $graySky;
  margin: 1em 0; padding: 0;
}

.stepsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
