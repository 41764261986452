@import "../../../styles/colors";

.container {
  align-items: center;
  color: $blackSky;
  display: flex;
  flex-direction: column;
  font-size: 12pt;
  justify-content: center;
  padding: 1rem;

  .titleContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    justify-content: center;

    .title {
      margin-left: 10px;
    }
  }

  .buttonContainer {
    margin-top: 12px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alertWarning {
  background-color: $warning !important;
  color: $darkWarning  !important;
  display: inline !important;
}