@import "../../../styles/colors";

.upload {
  color: $teal;
  cursor: pointer;
  text-decoration: underline;
}

.fileContainer {
  align-items: center;
  color: $blackSky;
  display: flex;
  flex-direction: column;
  font-size: 12pt;
  justify-content: center;
  padding: 1rem;

  .selectedFile {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    justify-content: center;

    .fileName {
      margin-left: 10px;
    }
  }

  .upload {
    margin-top: 12px;
  }

}

.dropFileZone {
  padding: 0;
  margin: 0;
  
  & .placeholder {
    align-items: center;
    border-radius: 11px;
    border: 1px dashed $darkGray;
    color: $blackSky;
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    justify-content: center;
    padding: 3rem;

    & img {
      height: 32px;
      width: 32px;
      margin-right: 1rem;
    }

    .upload {
      margin-left: 8px;
      text-transform: lowercase;
    }

  }
}
