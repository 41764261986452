@import '../../../styles/colors';
@import '../../../styles/typography';
@import '../../../styles/responsive';

.container {
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  width: 100%;

  .stepContainer {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 2;

    @media screen and (min-width: $tablet) {
      flex-direction: row;
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: row;

    .icon {
      width: 2.5rem;
    }

    .title {
      font-family: $contentFont;
      font-size: 12pt;
      flex-wrap: nowrap;
      color: $graySky;      

      &.display {
        color: $darkGray;
      }

      &.active {
        color: $teal;
      }
    }
  }

  .content {
    color: $darkGray;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: $contentFont;
    padding: 1.25rem 0rem 1.25rem 2.5rem;

    &.inline {
      padding: 0.25rem;
      align-items: center;

      @media screen and (min-width: $tablet) {
        align-items: flex-end;
      }
    }

  }

  .nextStepButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2rem 0rem 0rem 2.5rem;
  }
}