@import '../../../styles/colors';
@import '../../../styles/typography';
@import '../../../styles/responsive';

.cycleContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  .cycleTitle {
    color: $label;
    font-family: $componentFont;
    flex: 2;
    justify-self: flex-start;
    text-transform: uppercase;
  }

  .options {
    flex: 1;
    align-items: flex-end;
  }

  @media screen and (min-width: $tablet) {
    flex-direction: row;
  }
}