@import "../../../styles/colors";

.buttons {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  
  .downloadButton {
    svg {
      fill: none;
      stroke: #646464;
      stroke-width: 1px;
    }
  }
}

