@import '../../styles/typography';
@import '../../styles/colors';
@import '../../styles/transitions';

.PopMenuOption {
  font-family: $contentFont;
  color: $lightDark;
  transition: $defaultTransition;
  cursor: pointer;
  position: relative;

  &:hover {
    color: $teal;
  }

  span {
    margin-left: 25px;
  }

  svg {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Parent {
  display: block;
  padding: 5px 20px;
}
