$sky: #5ebebd;
$teal: #319fa0;
$sea: #217378;
$night: #0d3d40;
$sun: #fac567;
$mustard: #e9a036;
$sand: #bb802a;
$water: #aedcda;

$black: black;
$white: white;
$paleSky: #ecf4f4;
$lightSky: #e0eceb;
$graySky: #d3e3e2;
$mediumSky: #8ba09f;
$darkSky: #566463;
$blackSky: #2e3837;
$paleGray: #f7f7f7;
$lightGray: #ededed;
$gray: #e3e3e3;
$mediumGray: #c3c3c3;
$lightDark: #787878;
$darkGray: #7e7e7e;

$red: #e24c4b;
$darkRed: #bf4443;
$green: #60c460;
$darkGreen: #4ca84b;

/* Edoo defaults */
$label: #6a696e;
$warning: #fefee7;
$darkWarning: #b9912c;
