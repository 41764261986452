@import '../../styles/colors';

.innerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 10px;
  }
}

.centered {
  align-items: center;

  label {
    margin-bottom: 0px !important;
  }
}

.configSection {
  margin: 20px 0px;
}

.switchContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .status {
    color: #aaa;
    margin-right: 1.5rem;
    font-size: 10.5pt;
    text-transform: capitalize;
  }
}

.requestUpdateContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;

  .labelDescription {
    color: #aaa;
    font-size: 9pt;
    margin-bottom: 1.25rem;
  }
}

.searchContainer {
  margin-top: 1rem;

  input {
    background: transparent;
    border: 0px;
    // height: 0px;
    margin: 0px;
  }

  ul {
    z-index: 1 !important;
  }
}

.configItem {
  padding: 0.75rem 0rem 0.75rem 0.5rem;
}
