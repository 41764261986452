@import '../../../styles/colors';

.accent {
  color: $teal !important;
}

.popMenu {
  z-index: 105;
  margin-left: 0.25rem;

  span {
    display: block;
    margin: initial !important;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    // white-space: nowrap;
  }

  div:nth-child(2) {
    border-radius: 0.75rem;
    max-width: 12rem;
    min-width: auto;
  }
}

.rootModal {
  & > div {
    overflow: auto !important;
  }
}

.modal {
  align-items: center;
  color: $label;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 42.5vw;

  .closeButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .body {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 90%;
  }
}

section {
  .title {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .successfullyUpdatedExplanation {
    background-color: #e9eeee;
    border-radius: 0.5em;
    margin: 0 1rem 1rem;
    max-height: 6.5vh;
    max-width: 100%;
    min-height: 6.5vh;
    min-width: 100%;
    overflow: auto;
    padding: 1rem 0;
  }

  .usersSection {
    max-width: 100%;
    min-width: 100%;
  }

  .userContainer {
    align-items: center;
    display: flex;
    margin: 0.5rem;
    min-height: 60px;

    .centered {
      align-items: center;
      display: flex;
      height: 60px;
      justify-content: center;
    }
  }

  .textContainer {
    align-items: initial !important;
    color: $teal;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    overflow: auto;
    padding: 0 0.25rem;
    width: 100%;

    span,
    small {
      margin: initial;
      max-width: 100%;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.subLabel {
        color: $label;
        font-weight: normal;
      }
    }
  }
}

footer {
  font-weight: 700;
  margin-top: 1rem;

  p {
    color: $label;
  }

  span {
    text-decoration: underline;
  }
}
