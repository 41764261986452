@import '../../styles/colors';
@import '../../styles/typography';

.table {
  border-collapse: collapse;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: 1px solid $graySky;
  display: block;
  font-family: $contentFont;
  font-size: 10pt;
  overflow: auto;
  width: 100%;
  min-height: 250px;
  max-height: 400px;


  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
  }

  .header {
    background: white;
    color: $blackSky;
    font-family: $componentFont;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    padding: 1.5rem;
    position: sticky;
    top: 0;
    white-space: nowrap;
    z-index: 3;
  }

  .row {
    padding: 0.25rem 1rem;
    text-align: center;
    
    & td {
      height: 50px;
    }
    
    &:nth-of-type(odd) {
      background:$lightSky;
    }

    & .errorMark {
      position: relative;
      
      & svg {
        color: $red;
        cursor: pointer;
      }

      & .toolTip {
        background: white;
        border-radius: 5px;
        box-shadow: 1px 1px 8px #eee;
        color: #696969;
        font-size: 8pt;
        top: 1rem;
        left: 2rem;
        opacity: 0;
        padding: 1rem;
        position: absolute;
        transition: visibility 0s, opacity .5s linear;
        min-width: 10rem;
        visibility: hidden;
        z-index: 2;

        & ul {
          list-style: none;
          margin: 0 !important;
          padding: 0 !important;
          padding-left: 15px;
        }

        & li {
          margin-bottom: 5px;
        }
      }

      &:hover {
        color: $sea !important;
        & .toolTip {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    & .noError {
      position: relative;
      
      & svg {
        color: $green;
        cursor: pointer;
      }
    }
  }
}
