@import '../../styles/colors';
@import '../../styles/transitions';

.IconCog {
  margin-right: 5px;
}

.manuContainer {
  padding: 10px 0;
}
