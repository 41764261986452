@import '../../styles/colors';

.white {
  color: $white;
}

.black {
  color: $black;
}

.label {
  color: $label;
}

.primary {
  color: $teal;
}

.disabled {
  color: $graySky;
}