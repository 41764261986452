.student-row {
    display: flex;
    gap: .7rem;
    align-items: center;
    padding: 10px 0;

    img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        background-color: #f4f4f4;
        box-shadow: 0 0 0 1px #e5e5e5;
    }

    label {
        padding: 0;
        margin: 0;
    }
}
