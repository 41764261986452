@import "../../../styles/colors";

.modal {
  align-items: center;
  color: $label;
  display: flex;
  flex-direction: column;
  text-align: center;

  .closeButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }

  .body {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 80%;

    .title {
      font-size: 2em;
      font-weight: 700;
      margin-bottom: 1rem;
  
      .accent {
        color: $teal !important;
      }
    }
  
    .content {
      font-size: 15px;
      max-width: 443px;
      text-align: justify;
    }
  
    .actions {
      text-align: center;
      padding: 35px 0px;
  
      .button {
        align-items: center;
        margin: 10px;
      }
    }
  }

}
  